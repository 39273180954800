<template>
  <div class="index">
    <div class="row row-cols-3">
      <div class="col-2"></div>
      <div class="col-8 home shadow-lg p-3 mb-5 bg-body rounded">
        <template v-if="!hasGalleries">
          <loader></loader>
        </template>
        <div>
          <div class="row row-col-2 gallery-container">
            <div
              class="col-6 pt-2 pb-3"
              v-for="(gallery, index) in galleries"
              :key="gallery.id"
              v-observe-visibility="
          index === galleries.length - 1 ? visibilityChanged : false
        "
            >
              <div class="border border-1 rounded gallery-card shadow-lg p-3 mb-5 bg-body rounded">
                <h4 class="shadow-sm p-3 mb-5 bg-body rounded">
                  <strong>{{gallery.name}}</strong>
                </h4>
                <div>
                  <carousel :per-page="1" :mouse-drag="true">
                    <slide v-for="(url, index) in gallery.imageUrls " :key="index">
                      <img :src="url" alt="..." />
                    </slide>
                  </carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</template>

<script>
import Galleries from "../../mixins/gallery-mixin";
import Loader from "./Loader.vue";

export default {
  mixins: [Galleries],
  data() {
    return {
      page: 0,
      isVisible: true,
    };
  },
  components: { Loader },
  computed: {
    hasGalleries() {
      return this.galleries.length > 0;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_ROOT");
      }
      return false;
    },
  },
  methods: {
    async fetch() {
      await this.getAllGaleriesGuest(this.page);
    },

    visibilityChanged(isVisible) {
      this.isVisible = isVisible;
      if (!this.isVisible) {
        return;
      }
      this.page++;
      this.fetch();
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style scoped>
li {
  list-style: none;
}
ul {
  display: flex;
  justify-content: space-around;
}
.gallery-card {
  text-align: center;
  padding: 1rem;
}

img {
  object-fit: cover;
  height: 400px;
  width: 80%;
}
@media (max-width: 950px) {
  .col-8 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  img {
    object-fit: cover;
    height: 400px;
  }
  .gallery-container {
    display: block;
  }
}
</style>