<template>
  <div>
    <div class="text-center p-3">
      <button
        type="button"
        class="btn btn-outline-danger"
        data-toggle="modal"
        data-target="#DeleteGalleryModal"
      >Изтриване</button>
    </div>
    <div
      class="modal fade"
      id="DeleteGalleryModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="DeleteGalleryModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="DeleteGalleryModalTitle">Изтриване на галерия</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-floating">
              <p>Сигурен ли сте че искате да изтриете тази галерия?</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Назад</button>
            <button
              type="button"
              class="btn btn-danger"
              @click.prevent="deleteGalery()"
              data-dismiss="modal"
            >Да</button>
          </div>
        </div>
        <div class="text-center">
          <template v-if="message">
            <p>{{message}}</p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GalleryService from "../../services/gallery.service";

export default {
  props: ["gallery"],
  data() {
    return {
      message: "",
    };
  },
  methods: {
    deleteGalery() {
      this.message = "Моля изчакайте";
      GalleryService.deleteGallery(this.gallery.id).then(() => {
        this.message = "";
        this.$router.go(0);
      });
    },
  },
};
</script>
          

<style scoped>
/* Input */
.hide {
  display: none;
}

.img-list {
  width: 50px; /* width of container */
  height: 50px; /* height of container */
  object-fit: cover;
}
</style>