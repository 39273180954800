<template>
  <div>
    <template v-if="showAdminBoard">
     <gallery></gallery>
    </template>
    <template v-else>
      <GalleryGuest></GalleryGuest>
    </template>
  </div>
</template>

<script>
import Gallery from './share/Gallery.vue';
import GalleryGuest from './share/GalleryGuest';

export default {
  components: {
    Gallery,
    GalleryGuest
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_ROOT");
      }
      return false;
    },
  },
};
</script>

<style scoped>
</style>