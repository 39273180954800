<template>
  <div class="loader"></div>
</template>

<script>
export default {
}
</script>

<style scoped>
/* LOADER */
.loader {
  margin: 0 auto;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 45px;
  height: 45px;
  /* margin: -76px 0 0 -76px; */
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #302f2f;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>>