import axios from 'axios';
import API_URL from '../plugins/api-path';
import authHeader from '../services/auth-header';

export default {
    data() {
        return {
            galleries: []
        }
    },
    methods: {
        async getAllGaleries(page) {
            try {
                let allGaleries;
                await axios.get(API_URL() + `/gallery/all?page=${page}`, { headers: authHeader() })
                .then(response => allGaleries = response.data.content )

                for (const index in allGaleries) {

                    this.galleries.push({
                        index,
                        ...allGaleries[index]
                    });
                }
            } catch(error) {
                if (error.response.status  === 401) {
                    this.$store.dispatch("auth/logout");
                    this.$router.push("/login");
                }
                this.$store.dispatch("alert/errorTime", "Unable to process your request this time. Please try again latter.");
            }
        },
        async getAllGaleriesGuest(page) {
            try {
                let allGaleries;
                await axios.get(API_URL() + `/guest/all?page=${page}`, { headers: authHeader() })
                .then(response => allGaleries = response.data.content )

                for (const index in allGaleries) {

                    this.galleries.push({
                        index,
                        ...allGaleries[index]
                    });
                }
            } catch(error) {
                if (error.response.status  === 401) {
                    this.$store.dispatch("auth/logout");
                    this.$router.push("/login");
                }
                this.$store.dispatch("alert/errorTime", "Unable to process your request this time. Please try again latter.");
            }
        }
    }
}