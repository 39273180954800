import axios from 'axios';
import authHeader from "./auth-header";

import API_URL from '../plugins/api-path';

class GalleryService {
    async createGalery(files, name) {
        const formData = new FormData();

        // APPEND FILE IN FORMDATA
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i], files[i].name)
        }
        formData.append('name', name);

        try {
            const response = await axios
                .post(API_URL() + '/gallery/create', formData, { headers: authHeader() });
            return response.data;
        } catch (error) {
            if (error.response.status === 401) {
                this.$store.dispatch("auth/logout");
                this.$router.push("/login");
            }
            this.$store.dispatch("alert/errorTime", "Unable to process your request this time. Please try again latter.");
        }
    }

    async deleteGallery(id) {
        try {
            const response = await axios
                .delete(API_URL() + `/gallery/delete/${id}`, { headers: authHeader() });
            return response.data;
        } catch (error) {
            if (error.response.status === 401) {
                this.$store.dispatch("auth/logout");
                this.$router.push("/login");
            }
            this.$store.dispatch("alert/errorTime", "Unable to process your request this time. Please try again latter.");
        }
    }
    
}

export default new GalleryService();