<template>
  <div>
    <div class="text-center p-3">
      <button
        type="button"
        class="btn btn-outline-success"
        data-toggle="modal"
        data-target="#CreateGalleryModal"
      >Добави</button>
    </div>
    <div
      class="modal fade"
      id="CreateGalleryModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="CreateGalleryModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="CreateGalleryModalTitle">Промяна на текст</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-floating">
              <form @submit.prevent="createGalery" class="form" enctype="multipart/form-data">
                <input
                  type="file"
                  name="imageUpload"
                  id="imageUpload"
                  class="hide"
                  @change="readURL"
                />
                <label
                  for="imageUpload"
                  class="btn btn-outline-secondary text-center justify-content-center"
                >Избери снимка</label>
                <div v-if="liveImages.length > 0">
                  <div class="card">
                    <div class="card-header">Всички снимки</div>
                    <ul class="list-group list-group-flush">
                      <table class="table list-group-item">
                        <tbody
                          v-for="(liveImage, index) in liveImages"
                          :key="index"
                          v-bind:class="{ selectedImage: selectedImage == index }"
                        >
                          <tr>
                            <th scope="row">
                              <div class="button-controll">
                                <a @click.prevent="removeImage(liveImage, index)">
                                  <i class="far fa-trash-alt"></i>
                                </a>
                              </div>
                            </th>
                            <td>
                              <img class="img-list" :src="liveImage" width="60" />
                            </td>
                            <td>
                              <div class="button-controll">
                                <a v-on:click="selectedImage = index; move('up', liveImage);">
                                  <i class="far fa-caret-square-up"></i>
                                </a>
                              </div>
                            </td>
                            <td>
                              <div class="button-controll">
                                <a v-on:click="move('down', liveImage)">
                                  <i class="fas fa-caret-square-down"></i>
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </ul>
                  </div>
                </div>
                <template v-if="message">
                  <p class="error-message">{{message}}</p>
                </template>
                <div class="name_form">
                  <label for="img">Добави име:</label>
                  <br />
                  <div class="input-group flex-nowrap">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Име"
                      v-model="name"
                      aria-label="Username"
                      aria-describedby="addon-wrapping"
                    />
                  </div>
                </div>

                <div class="text-center p-3">
                  <button value="Create" class="btn btn-success" type="submit">Създай</button>
                </div>
                <div v-if="message" class="pt-3">
                  <div class="loader"></div>
                  <p>{{message}}</p>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Назад</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GalleryService from "../../services/gallery.service";
import ResizeImage from "../../plugins/resizeImage";
import LiveResizeImege from "../../plugins/liveResizeImage";

export default {
  data() {
    return {
      selectedImage: null,
      selectedFiles: [],
      liveImages: [],
      currentFile: undefined,
      message: "",
      imgageFileName: "",

      name: "",
    };
  },
  methods: {
    //MOVE IMAGE UP AND DOWN
    move(direction, image) {
      var index = this.liveImages.indexOf(image);
      const selectedIndexImage = this.selectedFiles[index];
      // Move element
      if (direction == "up" && index != 0) {
        // Remove element
        this.liveImages.splice(index, 1);
        this.selectedFiles.splice(index, 1);
        // Put it back at an earlier position
        this.liveImages.splice(index - 1, 0, image);
        this.selectedFiles.splice(index - 1, 0, selectedIndexImage);
      } else if (direction == "down" && index != this.liveImages.length - 1) {
        // Remove element
        this.liveImages.splice(index, 1);
        this.selectedFiles.splice(index, 1);
        // Put it back at a later position
        this.liveImages.splice(index + 1, 0, image);
        this.selectedFiles.splice(index + 1, 0, selectedIndexImage);
      }
    },
    removeImage(file, index) {
      // this.$refs.upload.remove(file);
      this.selectedFiles.splice(index, 1);
      this.liveImages.splice(index, 1);
    },
    readURL(event) {
      this.message = "";
      //If File match image
      if (event.target.files[0].type.match("image.*")) {
        const reader = new FileReader();
        reader.onload = (e) => (this.originalImg = e.target.result);
        reader.readAsDataURL(event.target.files[0]); // convert to base64 string
        // END: preview original

        // START: preview resized
        this.imgageFileName = event.target.files[0].name;
        ResizeImage.resizeImage({
          file: event.target.files[0],
          maxSize: 850,
        })
          .then((resizedImage) => {
            var file = new File([resizedImage], this.imgageFileName);
            this.selectedFiles.push(file);
          })
          .catch((err) => {
            console.error(err);
          });
        LiveResizeImege.resizeImage({
          file: event.target.files[0],
          maxSize: 150,
        }).then((liveResizedImage) => {
          this.liveImages.push(URL.createObjectURL(liveResizedImage));
        });
        // END: preview resized

        //IF File match video
      } else {
        this.message = "Incorrect Format!";
      }
    },
    async createGalery() {
      this.currentFile = this.selectedFiles;
      this.message = "Моля изчакайте...";
      await GalleryService.createGalery(this.currentFile, this.name).then(
        () => {
          this.message = "";
          this.$router.go(0);
        }
      ),
        (error) => {
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.successful = false;
        };
    },
  },
};
</script>

<style scoped>
/* Input */
.hide {
  display: none;
}

.img-list {
  width: 50px; /* width of container */
  height: 50px; /* height of container */
  object-fit: cover;
}
</style>